<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      :show-options="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <debtor-list-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <subtitle-totals :labels="['Total Balance', 'Total Inv. Incl. GST']"
              :totals="[sumBalance, sumTotalIncGst]"
              :shows="[true, true]" />
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.invoiceId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td><a @click.prevent="gotoViewDetails(entity.invoiceType, entity.invoiceId, entity.quoteId, entity.isAssessmentEnabled)">{{ entity.invoiceRef }}</a></td>
                <td>{{ entity.customerName }}</td>
                <td>{{ $filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.balance | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.totalExGst | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.gst | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.totalIncGst | formatNumber($userInfo.locale) }}</td>
                <td>{{ entity.quoteNo }}</td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ entity.exported ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ entity.exported ? 'mdi-check' : '' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
                    data-tooltip="Click to view"
                    @click.prevent="gotoViewDetails(entity.invoiceType, entity.invoiceId, entity.quoteId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-file-document mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
      <vue-fab v-if="false"
        position="bottom-right"
        :z-index="30"
        :actions="fabActions"
        main-tooltip="Debtor List Actions"
        @printStatement="printStatement" />
      <debtor-list-print-statement-modal v-if="isPrintStatementModalActive"
        :active.sync="isPrintStatementModalActive"
        v-model="entitySummaries"
        @close="closePrintStatememtModal" />
      <advance-receipt-modal v-if="isLoadAdvPaymentModalActive"
        :active.sync="isLoadAdvPaymentModalActive"
        :show-amount="false"
        :show-icon="false"
        @close="closeModal()">
        <p slot="text-title">Advance Receipt Entry</p>
      </advance-receipt-modal>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import DebtorListListSideMenu from './DebtorListListSideMenu'
import DebtorListService from './DebtorListService'
import { AppHeaderButtonTypes, InvoiceTypes } from '@/enums'
import Guid from '@/components/Guid'
import BulmaTable from '@/components/BulmaTable'
import { VueFab } from '@/components/VueFab'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { Columns } from './columns'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import QuoteRoutes from '@/views/quote/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import SubtitleTotals from '@/components/SubtitleTotals'
import _transform from 'lodash/transform'
import store from '@/store'
import AdvanceReceiptModal from '../advancereceipt/AdvanceReceiptModal'
import DebtorListPrintStatementModal from './DebtorListPrintStatementModal'
import MiscQuoteRoutes from '@/views/miscquote/route-types'

export default {
  name: 'DebtorListListView',
  components: {
    AppDetailHeader,
    [DebtorListListSideMenu.name]: DebtorListListSideMenu,
    BulmaTable,
    VueFab,
    SubtitleTotals,
    AdvanceReceiptModal,
    DebtorListPrintStatementModal
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      accessRoutes: [],
      filter: {
        invoiceNo: '',
        debtorName: '',
        quoteNo: '',
        filterType: 1,
        dateFrom: '',
        dateTo: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18,
        exported: 'B'
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false,
      isPrintStatementModalActive: false,
      isLoadAdvPaymentModalActive: false,
      fabActions: []
    }
  },
  computed: {
    sumBalance() {
      return this.entitySummaries.length > 0 ? this.entitySummaries[0].sumBalance : 0
    },
    sumTotalIncGst() {
      return this.entitySummaries.length > 0 ? this.entitySummaries[0].sumTotalIncGst : 0
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    },
    invoiceTypes() {
      return InvoiceTypes
    }
  },
  created() {
    this.setupFabButtons()
    this.getEntitySummaries()
    this.getAccessRoutes()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.PrintDebtorListStatement:
          this.printStatement()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.invoiceNo = ''
      this.filter.debtorName = ''
      this.filter.quoteNo = ''
      this.filter.filterType = 1
      this.filter.dateFrom = ''
      this.filter.dateTo = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.filter.exported = 'B'
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await DebtorListService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    async getAccessRoutes() {
      this.accessRoutes = await DebtorListService.getAccessRoutes()
    },
    async gotoViewDetails(invoiceType, invoiceId, quoteId, isAssessmentEnabled) {
      let receiptEntryRoute = this.accessRoutes.records.filter((obj) => {
        return obj.code === 'A013'
      })

      switch (true) {
        case invoiceType === InvoiceTypes.Quote || invoiceType === InvoiceTypes.Gst: //1, 4
          if (isAssessmentEnabled) {
            this.$router.push({
              name: QuoteRoutes.QuoteAssessmentInvoice.name,
              params: { quoteId: quoteId, selectedInvoiceId: invoiceId },
              route: 'assessmentinvoice'
            })
          }
          else {
            this.setQuoteInvoiceReadOnlyView(true)
            this.setQuoteReturnRoute(this.$route)
            this.$router.push({
              name: QuoteRoutes.QuoteInvoice.name,
              params: { quoteId: quoteId },
              route: 'invoice'
            })
          }
          break
        case invoiceType === InvoiceTypes.Excess || invoiceType === InvoiceTypes.OwnerContribution: //2, 3
          this.setQuoteInvoiceReadOnlyView(true)
          this.setQuoteReturnRoute(this.$route)
          this.$router.push({
            name: QuoteRoutes.QuoteExcessInvoice.name,
            params: { quoteId: quoteId },
            route: 'excessinvoice'
          })
          break
        case invoiceType === InvoiceTypes.Quick:
          this.setQuickInvoiceReadOnlyView(true)
          this.setQuickInvoiceReturnRoute(this.$route)
          this.editStoreQuickInvoice(invoiceId)
          break
        case invoiceType === InvoiceTypes.Adjustment:
          this.setAdjustmentReadOnlyView(true)
          this.setDebtorAdjustmentReturnRoute(this.$route)
          this.editStoreDebtorAdjustment(invoiceId)
          break
        case invoiceType === InvoiceTypes.Miscellaneous:
          const accessRoute = this.accessRoutes.records.find((v) => (v.code = 'A091'))
          // console.log(accessRoute)
          if (accessRoute.version === '2') {
            this.setMiscQuoteInvoiceReadOnlyView(true)
            this.setMiscQuoteReturnRoute(this.$route)
            this.$router.push({
              name: MiscQuoteRoutes.MiscQuoteInvoice.name,
              params: { entityId: quoteId },
              route: 'invoice'
            })
          } else {
            let shortQuoteId = Guid.createShortGuid(quoteId)
            window.open(
              `${process.env.VUE_APP_ROOT_URI}/modules/InvoiceView/Invoices.aspx?receipt=TRUE&mqid=${shortQuoteId}&qid=${quoteId}&type=6`,
              'popUpMiscInvoice',
              'height=580,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,locationbars=no,directories=no, status=yes'
            )
          }
          break
        case invoiceType === InvoiceTypes.Advance && receiptEntryRoute[0].version.toUpperCase() === 'DEFAULT': //8
          window.open(`${process.env.VUE_APP_ROOT_URI}/modules/ReceiptEntryView/ReceiptEntry.aspx?Qtid=${invoiceId}&RedirectUrl=/uniq/v2/debtorlists`, '_self')
          break
        case invoiceType === InvoiceTypes.Advance && receiptEntryRoute[0].version.toUpperCase() === '2': //8
          this.setAdvanceReadOnlyView(true)
          await store.dispatch('advancereceipt/editStoreItemByInvoiceId', invoiceId)
          this.$nextTick(() => {
            this.isLoadAdvPaymentModalActive = true
          })
          break
      }
    },
    print() {
      let params = Object.assign(
        {
          companyid: this.$userInfo.companyId,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      params.exported = this.filter.exported
      // // Convert properties to lower case
      params = _transform(params, function (result, value, key) {
        if (key.toLowerCase() === 'debtorname') {
          result['cusname'] = value
        } else if (key.toLowerCase() === 'datefrom') {
          result['startdate'] = value
        } else if (key.toLowerCase() === 'dateto') {
          result['enddate'] = value
        } else if (key.toLowerCase() === 'sortorder') {
          result['sortdirection'] = value
        } else if (key.toLowerCase() === 'exported') {
          result['exported'] = value
        } else {
          result[key.toLowerCase()] = value
        }
      })

      const emailer = new Emailer()
      emailer.subject = 'Debtor List'
      emailer.reportName = 'AT_DebtorList'
      this.addEmailer(emailer)
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    closeModal() {
      this.isLoadAdvPaymentModalActive = false
    },
    async setupFabButtons() {
      this.fabActions.splice(this.fabActions.length, 1, {
        name: 'printStatement',
        icon: 'printer',
        color: '#a8a145',
        tooltip: 'Print Statement'
      })
    },
    printStatement() {
      this.isPrintStatementModalActive = true
    },
    closePrintStatememtModal() {
      this.isPrintStatementModalActive = false
    }
  }
}
</script>